import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { useNavigate } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Paper, ThemeProvider, LinearProgress } from '@mui/material';
import Header from '../components/combinationSearchComponents/Header';
import getTheme from '../components/combinationSearchComponents/configuration/Themes';
import SearchAndFilter from '../components/combinationSearchComponents/SearchAndFilter';
import { fetchAllCourses, fetchContentOfCourse, resetSelectedVideo, resetContentOfCourse, resetCourseMovieData, setSelectedVideoDetails } from '../features/coursesSlice';
import { resetCombinationData, resetQuestionsArchive } from '../features/combinationSearchSlice';
import { resetSolverData, resetQuestionsSolverArchive } from '../features/solverSearchSlice';
import { getCourseContent } from '../api/serverApis/coursesApi';
import { checkDomainForDisclaimerAndCheckDateOfUser } from '../api/serverApis/userApi';
import { fetchBlob, selectUrlByBlob } from '../features/blobFilesSlice';
import { Disclaimer } from '../components/authentication/DisclaimerComponent';
import { useLocation } from 'react-router-dom';

const CourseSelectedPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const courses = useSelector((state: RootState) => state.courses.courses);
  //const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const [searchTerm, setSearchTerm] = useState("");
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
  const [isLoading, setIsLoading] = useState(false); // Track loading state  
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);
  const fromSignup = location.state?.fromSignup || new URLSearchParams(location.search).get('fromSignup') === 'true';
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const toggleTheme = () => {
    setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    dispatch(resetSelectedVideo());
    dispatch(fetchAllCourses());
    dispatch(resetContentOfCourse());
    dispatch(resetCourseMovieData());
    dispatch(resetQuestionsArchive());
    dispatch(resetCombinationData());
    dispatch(resetSolverData());
    dispatch(resetQuestionsSolverArchive());
  }, [dispatch]);
  useEffect(() => {
    const checkDisclaimer = async () => {
      try {
        const result = await checkDomainForDisclaimerAndCheckDateOfUser();
        if (result.data.data) {
          setDisclaimerOpen(true); // Open the disclaimer if the result is true
        }
        if (!result.data.data) {
          setDisclaimerOpen(false); // Close the disclaimer if the result is false  
        }
      } catch (error) {
        console.error("Error checking disclaimer status", error);
      }
    };
    checkDisclaimer();
  }, []);
  useEffect(() => {
    if (!fromSignup && !localStorage.getItem('disclaimerAccepted')) {
      navigate('/login'); // Redirect to login or an appropriate page if disclaimer is not accepted
    }
  }, []);
  useEffect(() => {
    if (disclaimerAccepted) {
      localStorage.setItem('disclaimerAccepted', 'true');
      navigate('/home'); // Navigate to home page if disclaimer is accepted
    }
  }, [disclaimerAccepted, navigate]);
  // const handleCourseSelect = async (courseId: string) => {
  //   const result = await checkDomainForDisclaimerAndCheckDateOfUser();
  //   if (result.data.data) {
  //     setDisclaimerOpen(true); // Open the disclaimer if the result is true
  //     return;
  //   }
  //   // Set loading state to true before starting to fetch content
  //   setIsLoading(true);

  //   try {
  //     // Await fetching the content for the selected course
  //     const contentOfCourse: any = await getCourseContent(courseId);
  //     if (contentOfCourse) {
  //       const urls = contentOfCourse.data.map((item: any) => item.url);
  //       for (let url in urls) {
  //         getTheLocalFilesFromServer(urls[url]);
  //       }
  //     }

  //     // Once the content is fetched, select the first video
  //     const firstVideo = contentOfCourse && contentOfCourse.data[0];
  //     if (firstVideo) {
  //       const firstSubVideo = firstVideo.content && firstVideo.content[0];

  //       // Dispatch to set selected video details       
  //       dispatch(setSelectedVideoDetails({
  //         movieId: firstVideo.movieId,
  //         id: firstSubVideo?.id || "",
  //         courseId: courseId,
  //       }));


  //       // Navigate to the course page with the first video selected
  //       navigate(`/course`);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch course content", error);
  //   } finally {
  //     // Set loading state to false once data is loaded or error occurs
  //     setIsLoading(false);
  //   }
  // };
  const handleCourseSelect = async (courseId: string) => {
    const result = await checkDomainForDisclaimerAndCheckDateOfUser();
    if (result.data.data) {
      setDisclaimerOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      // Fetch the content for the selected course
      const contentOfCourse: any = await getCourseContent(courseId);

      if (contentOfCourse && contentOfCourse.data) {
        // Sort all content by subject in ascending order
        const sortedContent = contentOfCourse.data.sort((a: any, b: any) => a.subject.localeCompare(b.subject));

        // Define type priority
        const typePriority = ['mp4', 'video', 'vimeo', 'youtube', 'imbedPdf', 'pdf', 'mp3', 'audio', 'website', 'code'];

        // Filter content by type based on the priority list
        let selectedContent: any = null;

        for (const type of typePriority) {
          selectedContent = sortedContent.find((item: any) => determineContentType(item.url) === type);
          if (selectedContent) break; // Stop when the first match is found
        }

        // If we found any valid content, proceed to load it
        if (selectedContent) {
          const firstSubContent = selectedContent.content && selectedContent.content[0];

          // Dispatch to set selected video details if it's a video, or PDF if that's the fallback
          dispatch(setSelectedVideoDetails({
            movieId: selectedContent.movieId,
            id: firstSubContent?.id || "",
            courseId: courseId,
          }));

          // Fetch local files if needed
          getTheLocalFilesFromServer(selectedContent.url);

          // Navigate to the course page with the first content selected
          navigate(`/course`);
        }
      }
    } catch (error) {
      console.error("Failed to fetch course content", error);
    } finally {
      setIsLoading(false);
    }
  };


  const getTheLocalFilesFromServer = (thisUrl: string) => {
    const type = determineContentType(thisUrl);
    //if (type === 'mp4' || type === 'imbedPdf') {
    if (type === 'imbedPdf') {
      dispatch(fetchBlob(thisUrl));
    }
  }
  const determineContentType = (url: string) => {
    if (!url) return 'website';
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'youtube';
    } else if (url.includes('vimeo')) {
      return 'youtube';
    } else if (url.includes('.mp3')) {
      return 'mp3';
    } else if (url.includes('.pdf')) {
      return 'imbedPdf';
    } else if (url.includes('.mp4')) {
      return 'mp4';
    } else {
      return 'website';
    }
  };


  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const handleCloseDisclaimer = (accepted: boolean) => {
    setDisclaimerOpen(false);
    if (accepted) {
      setDisclaimerAccepted(true); // Set accepted to true if the user agreed
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Box position='fixed' top={0} left={0} width={'100%'} zIndex={1200}>
        <Header header={"Xarcam Courses"} themeMode={themeMode} toggleTheme={toggleTheme} />
      </Box>
      <Box sx={{ marginTop: '7rem', paddingBottom: '5rem' }}>
        {/* Show progress bar while loading */}
        {isLoading && <LinearProgress sx={{ width: '100%' }} />}
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Grid container spacing={2} >
            {courses && courses
              .filter(course => course.courseName.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((course) => (
                <Grid item xs={6} sm={4} md={3} key={course.courseId} sx={{ cursor: 'pointer' }}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      ':hover': {
                        transform: 'scale(1.05)',
                        transition: 'transform 0.2s'
                      }
                    }}
                    onClick={() => handleCourseSelect(course.courseId)}
                  >
                    <CardContent sx={{ paddingBottom: '0.5rem' }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          direction: isHebrew(course.courseName) ? 'rtl' : 'ltr',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                        }}
                      >
                        {course.courseName}
                      </Typography>
                    </CardContent>

                    <CardMedia
                      component="img"
                      height="250rem"
                      image={course.courseId === "hrPlus_demo"? "https://media.licdn.com/dms/image/v2/D4D0BAQG837WQLuW4mw/company-logo_200_200/company-logo_200_200/0/1712483344516/hrpluseducation_logo?e=2147483647&v=beta&t=GgLgfYWORLD80pmR0gCAbRUdC5rFVZ9ZMqcYrwcWl_w":course.courseImage}
                      alt={course.courseName}
                      sx={{
                        borderRadius: '0.5rem',
                        margin: '0.5rem',
                      }}
                    />


                  </Card>
                </Grid>
              ))}
          </Grid>
          <Paper sx={{ height: '2rem', position: 'fixed', bottom: 0, left: 0, right: 0, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SearchAndFilter onSearch={setSearchTerm} />
          </Paper>
        </Box>
      </Box>
      {disclaimerOpen && <Disclaimer open={disclaimerOpen} onCloseBoolean={handleCloseDisclaimer} />}

    </ThemeProvider>
  );
};

export default CourseSelectedPage;
